import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useFetcher from "@/utils/use-fetcher";
import { LightOrderSheet } from "@/features/order-sheets/order-sheet.type";

interface BulkConfirmByAgencyOrderSheetsRequest {
  orderedFigures: {
    orderSheetId: number;
    quantity: number | null;
    amountWithoutCondition: number;
    amount: number;
  }[];
}
export interface BulkConfirmByAgencyOrderSheetsResponse {
  orderSheets: LightOrderSheet[];
}

interface BulkConfirmByAgencyOrderSheetsPathParameter {
  agencyId: number;
  orderSheetSetId: number;
}

export const bulkConfirmByAgencyOrderSheetSetsTemplateUrl = `/agencies/:agencyId/order-sheet-sets/:orderSheetSetId/order-sheets/bulk-confirm-by-agency`;

export const bulkConfirmByAgencyOrderSheetSetsKey: YWRKey<BulkConfirmByAgencyOrderSheetsPathParameter> =
  {
    url: (parameter) => {
      return calcUrl(bulkConfirmByAgencyOrderSheetSetsTemplateUrl, parameter);
    },
    method: "put",
  };

export default function useBulkConfirmByAgencyOrderSheetSets() {
  const fetcher = useFetcher<
    BulkConfirmByAgencyOrderSheetsRequest,
    BulkConfirmByAgencyOrderSheetsResponse
  >();

  return useYWR(bulkConfirmByAgencyOrderSheetSetsKey, fetcher);
}
