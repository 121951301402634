import {
  Box,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import {
  BoutiqueConfirmationMetadata,
  ConditionType,
  LightOrderSheetSet,
  OrderSheetStatus,
} from "@/features/order-sheets/order-sheet.type";
import { Field, FieldProps, Form, Formik, FormikProps } from "formik";
import AppButton from "@/features/line-sheet-sets/app-button";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import * as Yup from "yup";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useConfirmByBoutiqueOrderSheetSet from "@/features/order-status/hooks/use-confirm-by-boutique-order-sheet-set";
import ToBeUpdatedOrderSheetList, {
  ToBeUpdatedOrderSheet,
} from "@/features/order-status/modals/to-be-updated-order-sheet-list";
import AppPriceInput from "@/features/invoices/app-price-input";
import PriceConditionExclusiveSelector from "@/features/order-status/price-condition-exclusive-selector";
import useYupHelper from "@/features/ui/form/use-yup-helper";
import { toTitle } from "@/utils/case";
import ChangeOrderSheetStatusConfirmMessage from "@/features/order-status/change-order-sheet-status-confirm-message";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { UpdateOrderSheetSetStatusItem } from "@/features/order-status/order-sheet-status-updater";
import { groupBy, uniqBy } from "lodash";
import BoutiqueConfirmationMetadataController from "@/features/order-status/boutique-confirmation-metadata-controller";
import { PriceConditionOption } from "@/features/order-status/price-condition-selector";

export interface ConfirmByBoutiqueOrderSheetSetModalProps {
  agencyId: number;
  orderSheetSetId: number;
  orderSheetSet: UpdateOrderSheetSetStatusItem;
  toBeUpdateOrderSheets: ToBeUpdatedOrderSheet[];
  onConfirm: (response: LightOrderSheetSet) => void;
  isOpen: boolean;
  onClose: () => void;
}

export interface BoutiqueConfirmationMetadataFormField {
  toBeSubmitted: { id: number; name: string };
  orderSheets: { id: number; name: string }[];
  totalQuantity: number;
  totalAmount: number;
  conditions: PriceConditionOption[];
}

interface FormFields {
  boutiqueConfirmationMetadataList: BoutiqueConfirmationMetadataFormField[];
}

export function getPriceConditionKey(obj: PriceConditionOption) {
  const fields: (keyof PriceConditionOption)[] = [
    "category",
    "brand",
    "gender",
    "type",
  ];

  return fields
    .map((field) => {
      const value = obj[field];
      if (value !== undefined) {
        if (field === "brand" && typeof value === "object") {
          return value.name;
        }
        return value;
      }
      return "";
    })
    .join("-");
}

export default function ConfirmByBoutiqueOrderSheetSetModal({
  isOpen,
  onClose,
  agencyId,
  orderSheetSetId,
  orderSheetSet,
  toBeUpdateOrderSheets,
  onConfirm,
}: ConfirmByBoutiqueOrderSheetSetModalProps) {
  const formikRef = useRef<FormikProps<FormFields> | null>(null);
  const { error: showError } = useAppToasts();
  const { t, tTitle } = useI18nHelper();
  const { withMessage, formT } = useYupHelper({
    ns: "order_sheet_sets",
    prefix: "confirm_by_boutique_form",
  });

  const validateSchema = useMemo(() => {
    return Yup.object<FormFields>({
      boutiqueConfirmationMetadataList:
        Yup.array<BoutiqueConfirmationMetadataFormField>()
          .of(
            Yup.object<BoutiqueConfirmationMetadataFormField>({
              totalAmount: Yup.number()
                .label("total_confirmed_amount")
                .positive(withMessage.positive)
                .required(withMessage.required),
              totalQuantity: Yup.number()
                .label("total_confirmed_quantity")
                .positive(withMessage.positive)
                .required(withMessage.required),
              conditions: Yup.array<PriceConditionOption>()
                .label("confirmed_conditions")
                .of(
                  Yup.object<PriceConditionOption>({
                    brand: Yup.object<PriceConditionOption["brand"]>()
                      .label("conditions.brand")
                      .required(withMessage.required),
                    category: Yup.string()
                      .label("conditions.category")
                      .required(withMessage.required),
                    gender: Yup.string()
                      .label("conditions.gender")
                      .required(withMessage.required),
                    type: Yup.mixed<ConditionType>()
                      .label("conditions.type")
                      .required(withMessage.required),
                    value: Yup.number()
                      .label("conditions.value")
                      .max(100, withMessage.max)
                      .required(withMessage.required)
                      .positive(withMessage.positive),
                  })
                )
                .test((value, context) => {
                  if (value) {
                    const conditions = value as PriceConditionOption[];

                    const keys = conditions.map(getPriceConditionKey);

                    for (const key of keys) {
                      if (keys.filter((k) => k === key).length > 1) {
                        return context.createError({
                          message:
                            t(
                              "order_sheet_sets:confirm_by_boutique_form.messages.error.duplicated_condition"
                            ) +
                            " " +
                            key,
                        });
                      }
                    }
                  }
                  return true;
                })
                .required(withMessage.required),
            })
          )
          .min(1, withMessage.min)
          .required(withMessage.required),
    });
  }, [
    t,
    withMessage.max,
    withMessage.min,
    withMessage.positive,
    withMessage.required,
  ]);

  const validate = useCallback(
    async (values: FormFields) => {
      try {
        await validateSchema.validate(values, { abortEarly: false });
      } catch (error) {
        showError(error, { id: "ConfirmByBoutiqueOrderSheetSetForm" });
        return error;
      }
    },
    [validateSchema, showError]
  );

  const {
    isLoading: isConfirmByBoutiqueOrderSheetSetLoading,
    data: confirmByBoutiqueOrderSheetSetData,
    error: confirmByBoutiqueOrderSheetSetError,
    fire: fireConfirmByBoutiqueOrderSheetSet,
  } = useConfirmByBoutiqueOrderSheetSet();

  useEffect(() => {
    showError(confirmByBoutiqueOrderSheetSetError);
  }, [showError, confirmByBoutiqueOrderSheetSetError]);

  const handleSubmit = useCallback(
    (values: FormFields) => {
      fireConfirmByBoutiqueOrderSheetSet(
        {
          agencyId,
          orderSheetSetId,
        },
        {
          // @ts-ignore
          boutiqueConfirmationMetadataList:
            values.boutiqueConfirmationMetadataList,
        }
      );
    },
    [fireConfirmByBoutiqueOrderSheetSet, agencyId, orderSheetSetId]
  );

  useEffect(() => {
    if (confirmByBoutiqueOrderSheetSetData) {
      onConfirm(confirmByBoutiqueOrderSheetSetData);
      onClose();
    }
  }, [confirmByBoutiqueOrderSheetSetData, onConfirm, onClose]);

  const dialogMessage = useMemo(() => {
    const value: OrderSheetStatus = "BOUTIQUE_CONFIRMED";
    return (
      <ToBeUpdatedOrderSheetList
        header={<ChangeOrderSheetStatusConfirmMessage status={value} />}
        orderSheets={toBeUpdateOrderSheets}
      />
    );
  }, [toBeUpdateOrderSheets]);

  const initialValues = useMemo((): FormFields => {
    return {
      boutiqueConfirmationMetadataList: Object.entries(
        groupBy(
          orderSheetSet.orderSheets.filter((orderSheet) => {
            return orderSheet.toBeSubmitted !== null;
          }),
          (orderSheet) => orderSheet.toBeSubmitted!.id
        )
      ).map(([key, value]) => {
        return {
          toBeSubmitted: value[0].toBeSubmitted!,
          orderSheets: value,
          totalQuantity: value.reduce((acc, orderSheet) => {
            return acc + orderSheet.confirmedQuantity;
          }, 0),
          totalAmount: value.reduce((acc, orderSheet) => {
            return acc + orderSheet.confirmedAmount;
          }, 0),
          conditions: [
            {
              brand: {
                id: 0,
                name: "",
              },
              category: "ALL",
              gender: "ALL",
              type: "DISCOUNT",
              value: 0,
            },
          ],
        };
      }),
    };
  }, [orderSheetSet]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          color={"#444440"}
          fontSize={"14px"}
          background={"#F7F9FA"}
          borderBottom={"0.5px solid #8F8F8C"}
        >
          {tTitle("change_status")}
        </ModalHeader>
        <ModalBody>
          <Formik<FormFields>
            innerRef={formikRef}
            initialValues={initialValues}
            validate={validate}
            validateOnChange={false}
            validateOnBlur={false}
            validateOnMount={false}
            onSubmit={(values, formikHelpers) => {
              handleSubmit(values);
              formikHelpers.setSubmitting(false);
            }}
          >
            {(props) => {
              return (
                <Form>
                  <Box marginBottom={"12px"}>{dialogMessage}</Box>
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
        <ModalFooter display={"flex"} gap={"12px"}>
          <AppButton
            onClick={() => {
              onClose();
            }}
          >
            {tTitle("not_now")}
          </AppButton>
          <AppButton
            variant={"primary"}
            isLoading={isConfirmByBoutiqueOrderSheetSetLoading}
            onClick={() => {
              if (formikRef.current) {
                formikRef.current.submitForm();
              }
            }}
          >
            {tTitle("change")}
          </AppButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
