import AppFormModal from "@/features/ui/app-form-modal";
import { Field, FieldProps, Form, Formik } from "formik";
import { FormControl, FormLabel, Grid, Text } from "@chakra-ui/react";
import AppInput from "@/features/ui/app-input";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useYupHelper from "@/features/ui/form/use-yup-helper";
import React, { useCallback, useEffect, useMemo } from "react";
import * as Yup from "yup";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useIdentity from "@/features/ui/hooks/use-identity";
import useCreateIssuedBy from "@/features/invoices/triangular-trades/hooks/use-create-issued-by";
import { IssuedBy } from "@/features/invoices/triangular-trades/triangular-trade.type";
import AppSelect from "@/features/line-sheet-sets/app-select";
import enLocale from "i18n-iso-countries/langs/en.json";

export interface CreateIssuedByModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCreate?: (issued_by: IssuedBy) => void;
}

interface FormFields {
  companyName: string;
  businessRegistrationNumber: string;
  representativeName: string;
  representativeEmail: string;
  representativeMobileNumber: string;
  pec: string;
  country: string;
  state: string;
  city: string;
  streetAddress: string;
  detailAddress: string;
  zipCode: string;
  rea: string;
  capital: string;
}

export default function CreateIssuedByModal({
  isOpen,
  onClose,
  onCreate,
}: CreateIssuedByModalProps) {
  const { t, tTitle } = useI18nHelper();
  const identity = useIdentity();
  const { error: showError } = useAppToasts();
  const { withMessage } = useYupHelper({
    ns: "common",
    prefix: "create_issued_by_form",
  });

  const validateSchema = useMemo(() => {
    return Yup.object({
      companyName: Yup.string()
        .label("company_name")
        .required(withMessage.required),
      businessRegistrationNumber: Yup.string()
        .label("business_registration_number")
        .required(withMessage.required),
      country: Yup.string().label("country").required(withMessage.required),
      city: Yup.string().label("city").required(withMessage.required),
    });
  }, [withMessage.required]);

  async function validate(values: FormFields) {
    try {
      await validateSchema.validate(values, { abortEarly: false });
    } catch (e: any) {
      showError(e);
      return e;
    }
  }

  const {
    fire: fireCreateIssuedBy,
    error: createIssuedByError,
    data: createIssuedByData,
  } = useCreateIssuedBy();

  useEffect(() => {
    if (createIssuedByError) {
      showError(createIssuedByError);
    }
  }, [createIssuedByError, showError]);

  useEffect(() => {
    if (createIssuedByData) {
      onCreate?.(createIssuedByData);
      onClose();
    }
  }, [createIssuedByData, onClose, onCreate]);

  const handleSubmit = useCallback(
    async (values: FormFields) => {
      if (identity?.company) {
        type Country = typeof enLocale.countries;
        type key = keyof Country
        let country = enLocale.countries[values.country as key];
        if (Array.isArray(country)) {
          country = country[0];
        }
        return fireCreateIssuedBy(
          {
            by: identity.company.type,
            companyId: identity?.company?.id,
          },
          {
            companyName: values.companyName,
            businessRegistrationNumber: values.businessRegistrationNumber,
            representativeName: values.representativeName,
            representativeEmail: values.representativeEmail,
            representativeMobileNumber: values.representativeMobileNumber,
            pec: values.pec,
            country: country,
            state: values.state,
            city: values.city,
            streetAddress: values.streetAddress,
            detailAddress: values.detailAddress,
            zipCode: values.zipCode,
            rea: values.rea,
            capital: values.capital,
          }
        );
      }
    },
    [fireCreateIssuedBy, identity]
  );

  const options = useMemo(() => {
    if (!enLocale.countries) {
      return [];
    }
    return Object.entries(enLocale.countries).map(([key, value]) => ({
      name: t(`countries\:${key}`),
      value: key,
    }));
  }, [t]);
  options.sort((a, b) => a.name.localeCompare(b.name));
  options.sort((a, b) => {
    if (a.value === "KR") {
      return -1;
    }
    if (b.value === "KR") {
      return 1;
    }
    if (a.value === "IT") {
      return -1;
    }
    if (b.value === "IT") {
      return 1;
    }
    return 0;
  });

  return (
    <Formik<FormFields>
      initialValues={{
        companyName: "",
        businessRegistrationNumber: "",
        representativeName: "",
        representativeEmail: "",
        representativeMobileNumber: "",
        pec: "",
        country: "",
        state: "",
        city: "",
        streetAddress: "",
        detailAddress: "",
        zipCode: "",
        rea: "",
        capital: "",
      }}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      onSubmit={handleSubmit}
    >
      <AppFormModal
        title={"create_issued_by_form.title"}
        submitName={"add.do"}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Text fontSize={"12px"}>{t("create_departure_form.message")}</Text>

        <Form>
          <Grid
            templateColumns={"1fr 1fr"}
            templateRows={"repeat(6, 64px)"}
            gap={"8px"}
          >
            <Field name="companyName">
              {(props: FieldProps) => {
                return (
                  <FormControl
                    display={"grid"}
                    gridTemplateColumns={"1fr"}
                    gridTemplateRows={"16px 40px"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    marginTop={"18px"}
                    isRequired
                  >
                    <FormLabel
                      m={"0"}
                      p={"0"}
                      fontSize={"12px"}
                      flexShrink={"0"}
                    >
                      {tTitle("create_issued_by_form.field.company_name.label")}
                    </FormLabel>
                    <AppInput
                      placeholder={t(
                        "common:create_departure_form.field.placeholder",
                        {
                          fieldName: tTitle(
                            "create_issued_by_form.field.company_name.label"
                          ),
                        }
                      )}
                      value={props.field.value}
                      onChange={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </FormControl>
                );
              }}
            </Field>
            <Field name="businessRegistrationNumber">
              {(props: FieldProps) => {
                return (
                  <FormControl
                    display={"grid"}
                    gridTemplateColumns={"1fr"}
                    gridTemplateRows={"16px 40px"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    marginTop={"18px"}
                    isRequired
                  >
                    <FormLabel
                      m={"0"}
                      p={"0"}
                      fontSize={"12px"}
                      flexShrink={"0"}
                    >
                      {tTitle("business_number")}
                    </FormLabel>
                    <AppInput
                      placeholder={t(
                        "common:create_departure_form.field.placeholder",
                        {
                          fieldName: tTitle("business_number"),
                        }
                      )}
                      value={props.field.value}
                      onChange={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </FormControl>
                );
              }}
            </Field>

            <Field name="country">
              {(props: FieldProps) => {
                return (
                  <FormControl
                    display={"grid"}
                    gridTemplateColumns={"1fr"}
                    gridTemplateRows={"16px 40px"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    marginTop={"18px"}
                    isRequired
                  >
                    <FormLabel
                      m={"0"}
                      p={"0"}
                      fontSize={"12px"}
                      flexShrink={"0"}
                    >
                      {tTitle("country")}
                    </FormLabel>
                    <AppSelect
                      name={t(
                        "common:create_departure_form.field.placeholder",
                        {
                          fieldName: tTitle("country"),
                        }
                      )}
                      options={options}
                      // width={width}
                      value={props.field.value}
                      onSelect={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                      isClearable={props.field.value ? true : false}
                    />
                  </FormControl>
                );
              }}
            </Field>

            <Field name="state">
              {(props: FieldProps) => {
                return (
                  <FormControl
                    display={"grid"}
                    gridTemplateColumns={"1fr"}
                    gridTemplateRows={"16px 40px"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    marginTop={"18px"}
                  >
                    <FormLabel
                      m={"0"}
                      p={"0"}
                      fontSize={"12px"}
                      flexShrink={"0"}
                    >
                      {tTitle("state")}
                    </FormLabel>
                    <AppInput
                      placeholder={t(
                        "common:create_departure_form.field.placeholder",
                        {
                          fieldName: tTitle("state"),
                        }
                      )}
                      value={props.field.value}
                      onChange={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </FormControl>
                );
              }}
            </Field>
            <Field name="city">
              {(props: FieldProps) => {
                return (
                  <FormControl
                    display={"grid"}
                    gridTemplateColumns={"1fr"}
                    gridTemplateRows={"16px 40px"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    marginTop={"18px"}
                    isRequired
                  >
                    <FormLabel
                      m={"0"}
                      p={"0"}
                      fontSize={"12px"}
                      flexShrink={"0"}
                    >
                      {tTitle("city")}
                    </FormLabel>
                    <AppInput
                      placeholder={t(
                        "common:create_departure_form.field.placeholder",
                        {
                          fieldName: tTitle("city"),
                        }
                      )}
                      value={props.field.value}
                      onChange={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </FormControl>
                );
              }}
            </Field>

            <Field name="streetAddress">
              {(props: FieldProps) => {
                return (
                  <FormControl
                    display={"grid"}
                    gridTemplateColumns={"1fr"}
                    gridTemplateRows={"16px 40px"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    marginTop={"18px"}
                  >
                    <FormLabel
                      m={"0"}
                      p={"0"}
                      fontSize={"12px"}
                      flexShrink={"0"}
                    >
                      {tTitle("street_address")}
                    </FormLabel>
                    <AppInput
                      placeholder={t(
                        "common:create_departure_form.field.placeholder",
                        {
                          fieldName: tTitle("street_address"),
                        }
                      )}
                      value={props.field.value}
                      onChange={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </FormControl>
                );
              }}
            </Field>
            <Field name="detailAddress">
              {(props: FieldProps) => {
                return (
                  <FormControl
                    display={"grid"}
                    gridTemplateColumns={"1fr"}
                    gridTemplateRows={"16px 40px"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    marginTop={"18px"}
                  >
                    <FormLabel
                      m={"0"}
                      p={"0"}
                      fontSize={"12px"}
                      flexShrink={"0"}
                    >
                      {tTitle("detail_address")}
                    </FormLabel>
                    <AppInput
                      placeholder={t(
                        "common:create_departure_form.field.placeholder",
                        {
                          fieldName: tTitle("detail_address"),
                        }
                      )}
                      value={props.field.value}
                      onChange={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </FormControl>
                );
              }}
            </Field>

            <Field name="zipCode">
              {(props: FieldProps) => {
                return (
                  <FormControl
                    display={"grid"}
                    gridTemplateColumns={"1fr"}
                    gridTemplateRows={"16px 40px"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    marginTop={"18px"}
                  >
                    <FormLabel
                      m={"0"}
                      p={"0"}
                      fontSize={"12px"}
                      flexShrink={"0"}
                    >
                      {tTitle("zip_code")}
                    </FormLabel>
                    <AppInput
                      placeholder={t(
                        "common:create_departure_form.field.placeholder",
                        {
                          fieldName: tTitle("zip_code"),
                        }
                      )}
                      value={props.field.value}
                      onChange={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </FormControl>
                );
              }}
            </Field>

            <Field name="representativeName">
              {(props: FieldProps) => {
                return (
                  <FormControl
                    display={"grid"}
                    gridTemplateColumns={"1fr"}
                    gridTemplateRows={"16px 40px"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    marginTop={"18px"}
                  >
                    <FormLabel
                      m={"0"}
                      p={"0"}
                      fontSize={"12px"}
                      flexShrink={"0"}
                    >
                      {tTitle("representative_name")}
                    </FormLabel>
                    <AppInput
                      placeholder={t(
                        "common:create_departure_form.field.placeholder",
                        {
                          fieldName: tTitle("representative_name"),
                        }
                      )}
                      value={props.field.value}
                      onChange={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </FormControl>
                );
              }}
            </Field>
            <Field name="representativeEmail">
              {(props: FieldProps) => {
                return (
                  <FormControl
                    display={"grid"}
                    gridTemplateColumns={"1fr"}
                    gridTemplateRows={"16px 40px"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    marginTop={"18px"}
                  >
                    <FormLabel
                      m={"0"}
                      p={"0"}
                      fontSize={"12px"}
                      flexShrink={"0"}
                    >
                      {tTitle("representative_email")}
                    </FormLabel>
                    <AppInput
                      placeholder={t(
                        "common:create_departure_form.field.placeholder",
                        {
                          fieldName: tTitle("representative_email"),
                        }
                      )}
                      value={props.field.value}
                      onChange={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </FormControl>
                );
              }}
            </Field>

            <Field name="pec">
              {(props: FieldProps) => {
                return (
                  <FormControl
                    display={"grid"}
                    gridTemplateColumns={"1fr"}
                    gridTemplateRows={"16px 40px"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    marginTop={"18px"}
                  >
                    <FormLabel
                      m={"0"}
                      p={"0"}
                      fontSize={"12px"}
                      flexShrink={"0"}
                    >
                      {tTitle("pec")}
                    </FormLabel>
                    <AppInput
                      placeholder={t(
                        "common:create_departure_form.field.placeholder",
                        {
                          fieldName: tTitle("pec"),
                        }
                      )}
                      value={props.field.value}
                      onChange={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </FormControl>
                );
              }}
            </Field>
            <Field name="representativeMobileNumber">
              {(props: FieldProps) => {
                return (
                  <FormControl
                    display={"grid"}
                    gridTemplateColumns={"1fr"}
                    gridTemplateRows={"16px 40px"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    marginTop={"18px"}
                  >
                    <FormLabel
                      m={"0"}
                      p={"0"}
                      fontSize={"12px"}
                      flexShrink={"0"}
                    >
                      {tTitle("representative_mobile_number")}
                    </FormLabel>
                    <AppInput
                      placeholder={t(
                        "common:create_departure_form.field.placeholder",
                        {
                          fieldName: tTitle("representative_mobile_number"),
                        }
                      )}
                      value={props.field.value}
                      onChange={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </FormControl>
                );
              }}
            </Field>

            <Field name="rea">
              {(props: FieldProps) => {
                return (
                  <FormControl
                    display={"grid"}
                    gridTemplateColumns={"1fr"}
                    gridTemplateRows={"16px 40px"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    marginTop={"18px"}
                  >
                    <FormLabel
                      m={"0"}
                      p={"0"}
                      fontSize={"12px"}
                      flexShrink={"0"}
                    >
                      {tTitle("rea")}
                    </FormLabel>
                    <AppInput
                      placeholder={t(
                        "common:create_departure_form.field.placeholder",
                        {
                          fieldName: tTitle("rea"),
                        }
                      )}
                      value={props.field.value}
                      onChange={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </FormControl>
                );
              }}
            </Field>
            <Field name="capital">
              {(props: FieldProps) => {
                return (
                  <FormControl
                    display={"grid"}
                    gridTemplateColumns={"1fr"}
                    gridTemplateRows={"16px 40px"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    marginTop={"18px"}
                  >
                    <FormLabel
                      m={"0"}
                      p={"0"}
                      fontSize={"12px"}
                      flexShrink={"0"}
                    >
                      {tTitle("capital")}
                    </FormLabel>
                    <AppInput
                      placeholder={t(
                        "common:create_departure_form.field.placeholder",
                        {
                          fieldName: tTitle("capital"),
                        }
                      )}
                      value={props.field.value}
                      onChange={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </FormControl>
                );
              }}
            </Field>
          </Grid>
        </Form>
      </AppFormModal>
    </Formik>
  );
}
