import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useIdentity from "@/features/ui/hooks/use-identity";
import useRequiredParams from "@/features/invoices/hooks/use-required-params";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useGetTriangularTrade, {
  GetTriangularTradePathParameter,
  GetTriangularTradeRequest,
} from "@/features/invoices/triangular-trades/hooks/use-get-triangular-trade";
import { TriangularTradeFormFields } from "@/features/invoices/triangular-trades/upload-triangular-trade-page";
import useTriangularTradeFormValidationSchema from "@/features/invoices/triangular-trades/hooks/use-triangular-trade-form-validation-schema";
import { Formik } from "formik";
import { Grid, Heading } from "@chakra-ui/react";
import InvoiceDetailPageHeader from "@/features/invoices/bank-remittance-receipts/invoice-detail-page-header";
import TriangularTradeForm from "@/features/invoices/triangular-trades/triangular-trade-form";
import AppButton from "@/features/line-sheet-sets/app-button";
import AppDownloadButton from "@/features/invoices/app-download-button";
import { isStoredObject } from "@/features/types";
import AppListHeader from "@/features/ui/app-list-header";
import useReparseInvoice from "@/features/invoices/triangular-trades/hooks/use-reparse-invoice";

interface TriangularTradeDetailPageProps {
  isEditing?: boolean;
}

export default function TriangularTradeDetailPage({
  isEditing = false,
}: TriangularTradeDetailPageProps) {
  const { t, tTitle } = useI18nHelper();
  const { error: showError } = useAppToasts();
  const identity = useIdentity();
  const { triangularTradeId } = useRequiredParams<{
    triangularTradeId: number;
  }>();

  const getTriangularTradeKey = useMemo(():
    | {
        parameter: GetTriangularTradePathParameter;
        request: GetTriangularTradeRequest;
      }
    | undefined => {
    if (identity?.company && triangularTradeId) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
          triangularTradeId,
        },
        request: {},
      };
    }
  }, [identity, triangularTradeId]);

  const {
    data: getTriangularTradeData,
    error: getTriangularTradeError,
    mutate: mutateGetTriangularTrade,
  } = useGetTriangularTrade(
    getTriangularTradeKey?.parameter,
    getTriangularTradeKey?.request,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  // API: Reparse Invoice
  const {
    fire: fireReparseInvoice,
    isLoading: isReparseInvoiceLoading,
    data: reparseInvoiceData,
    error: reparseInvoiceError,
    clear: clearReparseInvoice,
  } = useReparseInvoice();

  useEffect(() => {
    if (reparseInvoiceError) {
      showError(reparseInvoiceError);
    }
  }, [showError, reparseInvoiceError]);

  useEffect(() => {
    if (reparseInvoiceData) {
      mutateGetTriangularTrade();
      clearReparseInvoice();
    }
  }, [clearReparseInvoice, mutateGetTriangularTrade, reparseInvoiceData]);

  useEffect(() => {
    if (getTriangularTradeError) {
      showError(getTriangularTradeError);
    }
  }, [getTriangularTradeError, showError]);

  const validateSchema = useTriangularTradeFormValidationSchema();

  const validate = useCallback(
    async (values: TriangularTradeFormFields) => {
      try {
        await validateSchema.validate(values, { abortEarly: false });
      } catch (e) {
        showError(e);
        return e;
      }
    },
    [showError, validateSchema]
  );

  const initialValues = useMemo((): TriangularTradeFormFields => {
    if (getTriangularTradeData) {
      return {
        id: getTriangularTradeData.id,
        status: getTriangularTradeData.status,
        originallyIssuedBy: getTriangularTradeData.originallyIssuedBy,
        // departure: getTriangularTradeData.departure,
        issuedBy: getTriangularTradeData.issuedBy,
        issuedTo: getTriangularTradeData.issuedTo,
        bankInfo: getTriangularTradeData.bankInfo,
        forwarder: getTriangularTradeData.forwarder,
        incoterms: getTriangularTradeData.incoterms,
        ftaSignature: getTriangularTradeData.ftaSignature,
        issuedOn: getTriangularTradeData.issuedOn,
        invoiceNumber: getTriangularTradeData.invoiceNumber,
        referenceInvoiceNumber: getTriangularTradeData.referenceInvoiceNumber,
        markup: getTriangularTradeData.markup,
        packageNumber: getTriangularTradeData.packageNumber,
        totalWeight: getTriangularTradeData.totalWeight,
        comment: getTriangularTradeData.comment,
        importedFiles: getTriangularTradeData.importedFiles,
        exportedFile: getTriangularTradeData.exportedFile || undefined,
      };
    } else {
      return {};
    }
  }, [getTriangularTradeData]);

  const [isRefreshing, setIsRefreshing] = useState<boolean>();

  useEffect(() => {
    async function refresh() {
      if (!isEditing) {
        setIsRefreshing(true);
        await mutateGetTriangularTrade();
        setIsRefreshing(false);
      }
    }

    let intervalId: NodeJS.Timeout | null = null;
    if (getTriangularTradeData && !isEditing) {
      if (getTriangularTradeData.status === "PENDING") {
        intervalId = setInterval(refresh, 5000);
      }
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [getTriangularTradeData, isEditing, mutateGetTriangularTrade]);

  return (
    <Formik<TriangularTradeFormFields>
      initialValues={initialValues}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      enableReinitialize={true}
      onSubmit={async (values, formikHelpers) => {
        if (identity?.company) {
          // return await handleSubmit(
          //   {
          //     by: identity.company.type,
          //     companyId: identity.company.id,
          //     bankRemittanceReceiptId: values.id!,
          //   },
          //   values,
          //   formikHelpers
          // );
        }
        return false;
      }}
    >
      {(props) => {
        return (
          <Grid
            as={"form"}
            height={"100%"}
            width={"100%"}
            gridTemplateColumns={"1fr"}
            gridTemplateRows={"auto auto 1fr"}
          >
            <AppListHeader
              headers={[
                <Heading
                  key={"TriangularTrade"}
                  as={"h2"}
                  fontSize={"14px"}
                >
                  {tTitle("triangular_trades")}
                </Heading>,
              ]}
              filters={[]}
              actions={[
                <AppButton
                  key={"InvoiceDetailAction_Reparse"}
                  isLoading={isReparseInvoiceLoading}
                  onClick={() => {
                    fireReparseInvoice(
                      {
                        by: identity!.company!.type,
                        companyId: identity!.company!.id,
                        invoiceId: getTriangularTradeData!.id,
                      },
                      {}
                    );
                  }}
                >
                  {tTitle("reparse")}
                </AppButton>,
              ]}
              showNavigateToBack={true}
              emptyStackPage={"/triangular-trades"}
              blockNavigateToBack={props.dirty && isEditing}
            />
            <TriangularTradeForm
              formik={props}
              isEditing={isEditing}
              isRefreshing={isRefreshing}
            />
          </Grid>
        );
      }}
    </Formik>
  );
}
