import { TriangularTrade } from "@/features/invoices/triangular-trades/triangular-trade.type";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { AxiosRequestConfig } from "axios";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import useFetcher from "@/utils/use-fetcher";
import { CompanyType, StoredObject } from "@/features/types";

export interface CreateTriangularTradeRequest {
  originallyIssuedBy: string;
  // departure: { id: number };
  issuedBy: { id: number };
  issuedTo: { id: number };
  bankInfo: { id: number };
  forwarder: { id: number };
  incoterms: string;
  ftaSignature: { id: number };
  issuedOn: Date;
  invoiceNumber: string;
  referenceInvoiceNumber: string;
  markup: number;
  packageNumber: number;
  totalWeight: number;
  comment: string;
  importedFiles: StoredObject[];
}

export interface CreateTriangularTradeResponse extends TriangularTrade {}

export interface CreateTriangularTradePathParameter {
  by: CompanyType;
  companyId: number;
}

export const createTriangularTradeTemplateUrl = `/:by/:companyId/triangular-trades`;

export const createTriangularTradeKey: YWRKey<CreateTriangularTradePathParameter> =
  {
    url: (parameters: CreateTriangularTradePathParameter) => {
      return calcUrl(createTriangularTradeTemplateUrl, parameters);
    },
    method: "post",
  };

export default function useCreateTriangularTrade(
  config?: AxiosRequestConfig<CreateTriangularTradeRequest>
) {
  const fetcher = useFetcher<
    CreateTriangularTradeRequest,
    CreateTriangularTradeResponse
  >();

  return useYWR(createTriangularTradeKey, fetcher, config);
}
