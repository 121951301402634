import { ICellRendererParams } from "ag-grid-community";
import {
  FlatProduct,
  isOverlappedValue,
  OverlappedValue,
} from "@/features/line-sheet-sets/helpers/sheet-state";
import { Flex, Grid, Text } from "@chakra-ui/react";
import { useMemo } from "react";

export interface OverlappedValueCellProps {
  unit?: string;
  isEditable?: boolean;
  topTextAlign?: "start" | "end" | "center";
}

export default function OverlappedValueCell<T>({
  api,
  unit,
  value,
  topTextAlign,
  isEditable = false,
}: OverlappedValueCellProps &
  ICellRendererParams<FlatProduct, OverlappedValue<T> | T>) {
  // const inputRef = useRef<HTMLInputElement>(null);

  // console.dir(value);

  const [top, under, colorCode] = useMemo(() => {
    if (isOverlappedValue(value, (item) => item !== undefined)) {
      return [value.top, value.under, value.colorCode] as const;
    } else {
      return [value, undefined, undefined];
    }
  }, [value]);

  const isContentOverlapped = under !== undefined && top != under;
  const isContentDifferent = under !== undefined && top != under;

  if (top == 0 && under == 0 && unit != "%") {
    return;
  }

  let color = colorCode;
  if (!colorCode) {
    color = isContentDifferent ? "#CC1D1D" : undefined;
  }

  return (
    <Grid
      width={"100%"}
      gridTemplateColumns={isContentOverlapped ? "1fr 1fr" : "1fr"}
      alignItems={"center"}
      justifyItems={"center"}
      gridTemplateRows={"24px"}
      // padding={"0 4px"}
      gap={"4px"}
      // backgroundColor={"red.200"}
    >
      <Text
        justifySelf={
          topTextAlign ? topTextAlign : isContentOverlapped ? "end" : "center"
        }
        // justifySelf={"end"}
        color={color}
        textAlign={"right"}
        fontFamily={"Inter"}
        fontSize={"12px"}
        fontStyle={"normal"}
        fontWeight={400}
        lineHeight={"normal"}
      >
        {(top === -1 ? "∞" : top) as string}
        {unit}
      </Text>

      {isContentOverlapped && (
        <Flex
          flexDirection={"row"}
          alignItems={"center"}
          color={"#8F8F8C"}
          justifySelf={"start"}
        >
          (
          <Text
            textAlign={"right"}
            fontFamily={"Inter"}
            fontSize={"12px"}
            fontStyle={"normal"}
            fontWeight={400}
            lineHeight={"normal"}
            textDecorationLine={"line-through"}
          >
            {(under === -1 ? "∞" : under) as string}
            {unit}
          </Text>
          )
        </Flex>
      )}
    </Grid>
  );
}
