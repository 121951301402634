import { ProductPrice } from "@/features/types";
import {
  FlatProduct,
  FlatSheetTab,
  InflatedProduct,
  InflatedTab,
  isFlatProductImageUrl,
} from "@/features/line-sheet-sets/helpers/sheet-state";
import { AppUtils } from "@/features/ui/utils/app-utils";
import { zip } from "lodash";

export class SheetFlattener {
  private flattenPrice(priceName: string, price: ProductPrice | null) {
    return {
      // priceName,
      priceAmount: price?.amount || 0,
      priceCurrency: price?.currency || null,
    };
  }

  private factory(id: number, index: number): FlatProduct {
    return {
      id,
      rowId: `${id}_${index}`,
      imageUrls: null,
      brandModelNumberModelName: null,
      genderCategorySubcategory: null,
      colorCodeColorNameMaterial: null,
      priceAmount: 0,
      priceCurrency: null,
      priceAdjustedByPartial: null,
      sizes: null,
      isFiltered: true,
    };
  }

  private extractFirst(inflatedProduct: InflatedProduct): FlatProduct {
    const {
      brandName,
      modelNumber,
      imageUrls,
      alternativeImageUrls,
      gender,
      supplyPrice,
      priceAdjustedBy,
      stockQuantityWithOptionList,
      isFiltered,
      colorCode,
      confirmedPriceAdjustedBy,
      confirmedPrice,
    } = inflatedProduct;

    const next: FlatProduct = {
      ...this.factory(inflatedProduct.id, 0),
      imageUrls: [
        ...imageUrls.map((url) => ({ url, type: "ORIGINAL" })),
        ...alternativeImageUrls.map((url) => ({ url, type: "ALTERNATIVE" })),
      ].filter(isFlatProductImageUrl),
      brandModelNumberModelName: brandName,
      genderCategorySubcategory: gender,
      colorCodeColorNameMaterial: colorCode,
      ...this.flattenPrice("Price", supplyPrice),
      // priceAdjustedByPartial: priceAdjustedBy.by,
      sizes: stockQuantityWithOptionList.map((i) => i.name),
      isFiltered,
    };

    if (confirmedPriceAdjustedBy) {
      next.priceAdjustedByPartial = {
        top: confirmedPriceAdjustedBy.by,
        under: priceAdjustedBy.by,
      };
    } else {
      next.priceAdjustedByPartial = priceAdjustedBy.by;
    }

    if (confirmedPrice) {
      next.priceAmount = {
        top: confirmedPrice.amount,
        under: supplyPrice?.amount || 0,
      };
    }

    return next;
  }

  private extractSecond(inflatedProduct: InflatedProduct): FlatProduct {
    const {
      name,
      note,
      categories,
      costPrice,
      priceAdjustedBy,
      stockQuantityWithOptionList,
      modelNumber,
      isFiltered,
      colorName,
      newStockWithOptionList,
      confirmedPriceAdjustedBy,
    } = inflatedProduct;

    const next: FlatProduct = {
      ...this.factory(inflatedProduct.id, 1),
      brandModelNumberModelName: modelNumber,
      genderCategorySubcategory: categories.length > 0 ? categories[0] : null,
      colorCodeColorNameMaterial: colorName,
      ...this.flattenPrice("Cost", costPrice),
      //priceAdjustedByPartial: priceAdjustedBy.value,
      //sizes: stockQuantityWithOptionList.map((i) => i.quantity),
      isFiltered,
    };

    if (stockQuantityWithOptionList) {
      if (newStockWithOptionList) {
        next.sizes = AppUtils.zip(
          stockQuantityWithOptionList,
          newStockWithOptionList,
          (stockQuantityWithOption, newStockWithOption) => {
            return {
              top: newStockWithOption!.quantity,
              under: stockQuantityWithOption!!.quantity,
            };
          }
        );
      } else {
        next.sizes = stockQuantityWithOptionList.map((i) => i.quantity);
      }
    }

    if (confirmedPriceAdjustedBy) {
      next.priceAdjustedByPartial = {
        top: confirmedPriceAdjustedBy.value,
        under: priceAdjustedBy.value,
      };
    } else {
      next.priceAdjustedByPartial = priceAdjustedBy.value;
    }

    return next;
  }

  private extractThird(inflatedProduct: InflatedProduct): FlatProduct {
    const {
      colorName,
      retailPrice,
      firstOrderQuantityWithOptionList,
      prevOrderQuantityWithOptionList,
      orderQuantityWithOptionList,
      name,
      categories,
      isFiltered,
      material,
      note,
    } = inflatedProduct;

    let modelName = name || "";

    if (note) {
      modelName += " " + Object.values(note).join(" ");
    }

    const next: FlatProduct = {
      ...this.factory(inflatedProduct.id, 2),
      brandModelNumberModelName: modelName,
      genderCategorySubcategory: categories.length > 1 ? categories[1] : null,
      colorCodeColorNameMaterial: material,
      ...this.flattenPrice("Retail", retailPrice),
      sizes: orderQuantityWithOptionList.map((i) => i.quantity),
      isFiltered,
    };

    if (orderQuantityWithOptionList) {
      if (firstOrderQuantityWithOptionList) {
          next.sizes = zip(orderQuantityWithOptionList, firstOrderQuantityWithOptionList, prevOrderQuantityWithOptionList).map(
            ([orderQuantityWithOption, firstOrderQuantityWithOption, prevOrderQuantityWithOption]) => {
              const top = orderQuantityWithOption!.quantity;
              const under = firstOrderQuantityWithOption
                ? firstOrderQuantityWithOption.quantity
                : 0
              const prev = prevOrderQuantityWithOption
                ? prevOrderQuantityWithOption.quantity
                : 0
              return {
                'top': top,
                'under': under,
                'colorCode': prev && top !== prev ? 'blue' : '#CC1D1D',
              }
            }
          )
      } else {
        next.sizes = orderQuantityWithOptionList.map((i) => i.quantity);
      }
    }

    return next;
  }

  flattenProduct(inflatedProduct: InflatedProduct): FlatProduct[] {
    return [this.extractFirst, this.extractSecond, this.extractThird].map(
      (f) => {
        return f.call(this, inflatedProduct);
      }
    );
  }

  flattenTab(inflatedTab: InflatedTab): FlatSheetTab {
    return {
      index: inflatedTab.index,
      name: inflatedTab.name,
      isDirty: false,
      products: inflatedTab.products.flatMap((i) => this.flattenProduct(i)),
    };
  }

  flattenTabs(inflatedTabs: InflatedTab[]): FlatSheetTab[] {
    return inflatedTabs.map((tab) => {
      return this.flattenTab(tab);
    });
  }
}
