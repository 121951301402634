import AppButton from "@/features/line-sheet-sets/app-button";
import useCreateOrderSheetRevision, {
  CreateOrderSheetRevisionResponse,
} from "@/features/line-sheet-sets/hooks/use-create-order-sheet-revision";
import { useCallback, useEffect, useMemo, useRef } from "react";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import {
  DenseOrderSheet,
  OrderSheetProduct,
} from "@/features/order-sheets/order-sheet.type";
import useIdentity from "@/features/ui/hooks/use-identity";
import { useRecoilValue } from "recoil";
import { Flex, Tooltip } from "@chakra-ui/react";
import { inflatedTabsFamily } from "@/features/line-sheet-sets/helpers/sheet-state";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import AppToolTip, { AppToolTipProps } from "@/features/ui/app-tooltip";

interface OrderSheetSetSaveButtonProps {
  orderSheetSetId: number;
  orderSheet: DenseOrderSheet | undefined;
  isOrderSheetEditing: boolean;
  /*
   * 리오더가 되는 경우 oss는 제출 상태, os는 주문서 작성 상태가 된다,
   * 모든 os의 제출 여부로 ordered여부 판단한다.
   * */
  isAllOrderSheetsOrdered: boolean;
  onSaveLoading: (isLoading: boolean) => void;
  isOrderSheetEditable: boolean;
  isConfirmedEditable: boolean;
  onSave: (response: CreateOrderSheetRevisionResponse) => void;
}

export default function OrderSheetSetSaveButton({
  orderSheetSetId,
  orderSheet,
  isAllOrderSheetsOrdered,
  isOrderSheetEditing,
  isOrderSheetEditable,
  onSaveLoading,
  onSave,
  isConfirmedEditable,
}: OrderSheetSetSaveButtonProps) {
  const isSaveLoadingRef = useRef<boolean>(false);
  const identity = useIdentity();
  const company = useMemo(() => {
    return identity?.company!!;
  }, [identity]);
  const { error: showError } = useAppToasts();
  const { t, tTitle } = useI18nHelper();

  const sheetKey = useMemo(() => {
    return {
      orderSheetSetId,
      orderSheetId: orderSheet?.id || -1,
    };
  }, [orderSheetSetId, orderSheet?.id]);

  const inflatedTabs = useRecoilValue(inflatedTabsFamily(sheetKey));

  const {
    isLoading: isCreateOrderSheetRevisionLoading,
    error: createOrderSheetRevisionError,
    fire: fireCreateOrderSheetRevision,
    data: createOrderSheetRevisionData,
    clear: clearCreateOrderSheetRevisionData,
  } = useCreateOrderSheetRevision();

  const isOrderSheetDirty = useMemo(() => {
    if (inflatedTabs) {
      return inflatedTabs.tabs.find((i) => i.isDirty) !== undefined;
    }
    return false;
  }, [inflatedTabs]);

  const handleSave = useCallback(async () => {
    if (
      !isSaveLoadingRef.current &&
      orderSheet &&
      inflatedTabs &&
      identity &&
      identity.company
    ) {
      isSaveLoadingRef.current = true;
      const buyerId = identity.company.id;
      try {
        await fireCreateOrderSheetRevision(
          {
            by: identity.company.type,
            companyId: buyerId,
            orderSheetSetId,
            orderSheetId: orderSheet.id,
          },
          {
            orderSheetTabs: inflatedTabs.tabs.map((tab) => {
              return {
                index: tab.index,
                products: tab.products.map((product): OrderSheetProduct => {
                  return {
                    id: product.id,
                    priceAdjustedBy: product.priceAdjustedBy,
                    supplyPrice: product.supplyPrice,
                    firstOrderQuantityWithOptionList:
                    product.firstOrderQuantityWithOptionList,
                    prevOrderQuantityWithOptionList:
                    product.prevOrderQuantityWithOptionList,
                    latestOrderQuantityWithOptionList:
                      product.latestOrderQuantityWithOptionList,
                    confirmedQuantityWithOptionList:
                      product.confirmedQuantityWithOptionList,
                    orderQuantityWithOptionList:
                      product.orderQuantityWithOptionList,
                    newStockWithOptionList: product.newStockWithOptionList,
                    confirmedPrice: product.confirmedPrice,
                    confirmedPriceAdjustedBy: product.confirmedPriceAdjustedBy,
                  };
                }),
              };
            }),
          }
        );
      } catch (e) {
        showError(e);
      } finally {
        isSaveLoadingRef.current = false;
      }
    }
  }, [
    orderSheetSetId,
    orderSheet,
    identity,
    fireCreateOrderSheetRevision,
    inflatedTabs,
    showError,
  ]);

  useEffect(() => {
    onSaveLoading(isCreateOrderSheetRevisionLoading);
  }, [isCreateOrderSheetRevisionLoading, onSaveLoading]);

  useEffect(() => {
    if (createOrderSheetRevisionData) {
      onSave(createOrderSheetRevisionData);
    }
  }, [onSave, createOrderSheetRevisionData]);

  const tooltipProps = useMemo(():
    | Pick<AppToolTipProps, "level" | "label">
    | undefined => {
    if (!isOrderSheetDirty) {
      return {
        level: "ERROR",
        label: t("save.disabled.no_change"),
      };
    } else if (isOrderSheetEditing) {
      return {
        level: "ERROR",
        label: t("common:save.disabled.not_committed"),
      };
    }

    if (identity?.company?.type === "AGENCY" && isConfirmedEditable) {
      return undefined;
    }

    if (isAllOrderSheetsOrdered) {
      return {
        level: "SUCCESS",
        label: t("common:order.disabled.already_ordered"),
      };
    } else if (!isOrderSheetEditable) {
      return {
        level: "ERROR",
        label: t("save.disabled.not_state_of_editable"),
      };
    } else if (!isOrderSheetDirty) {
      return {
        level: "ERROR",
        label: t("save.disabled.no_change"),
      };
    }
  }, [
    isOrderSheetDirty,
    identity?.company?.type,
    isConfirmedEditable,
    isAllOrderSheetsOrdered,
    isOrderSheetEditable,
    isOrderSheetEditing,
    t,
  ]);

  return (
    <AppToolTip
      level={tooltipProps?.level}
      hasArrow={true}
      label={tooltipProps?.label}
      isDisabled={tooltipProps === undefined}
      modifiers={[
        {
          name: "preventOverflow",
          options: {
            padding: 8,
          },
        },
      ]}
    >
      <Flex>
        <AppButton
          width={"120px"}
          isDisabled={tooltipProps !== undefined}
          isLoading={
            isCreateOrderSheetRevisionLoading || isSaveLoadingRef.current
          }
          onClick={handleSave}
        >
          {tTitle("save")}
        </AppButton>
      </Flex>
    </AppToolTip>
  );
}
