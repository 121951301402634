import { ICellRendererParams } from "ag-grid-community";
import {
  FlatProduct,
  OverlappedValue,
} from "@/features/line-sheet-sets/helpers/sheet-state";
import AppButton from "@/features/line-sheet-sets/app-button";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { useMemo } from "react";
import { AgGridUtils } from "@/features/ui/utils/ag-grid-utils";

export interface TakeAllProductsCellProps {
  isEditable?: boolean;
  isOrderEditable?: boolean;
}

export default function TakeAllProductsCell(
  props: TakeAllProductsCellProps & ICellRendererParams<FlatProduct, boolean>
) {
  const { api, rowIndex, data } = props;
  const { tTitle } = useI18nHelper();

  const isTakenAll = useMemo(() => {
    const stockData = api.getDisplayedRowAtIndex(rowIndex - 1)?.data?.sizes;
    const orderData = data?.sizes;

    const getQuantity = (datum: number | OverlappedValue<number>) => {
      if (typeof datum === "number") {
        return datum;
      } else {
        return datum.top;
      }
    };

    if (stockData && orderData) {
      // @ts-ignore
      return orderData.every(
        (orderDatum: number | OverlappedValue<number>, index: number) => {
          return (
            getQuantity(orderDatum) >=
            getQuantity(stockData[index] as number | OverlappedValue<number>)
          );
        }
      );
    }

    return true;
  }, [api, data, rowIndex]);

  if (!props.isOrderEditable) {
    return null;
  }

  return (
    <AppButton
      height={"22px"}
      isDisabled={isTakenAll}
      onClick={() => {
        AgGridUtils.dispatchCellEditEvent(true, props);
      }}
    >
      {tTitle("take_all")}
    </AppButton>
  );
}
