import useIdentity from "@/features/ui/hooks/use-identity";
import { useCallback, useEffect, useMemo, useRef } from "react";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useListAllSheetTabs, {
  ListAllSheetTabsPathParameter,
  ListAllSheetTabsRequest,
} from "@/features/line-sheet-sets/hooks/use-list-all-sheet-tabs";
import { useSetRecoilState } from "recoil";
import { inflatedTabsFamily } from "@/features/line-sheet-sets/helpers/sheet-state";
import {
  isProductFiltered,
  SheetTabFilterQuery,
} from "@/features/order-sheet-sets/sheet-tab-filter";
import { AppUtils } from "@/features/ui/utils/app-utils";
import { isDirty } from "@/utils/form";
import useInflatedTabs from "@/features/line-sheet-sets/helpers/use-inflated-tabs";

interface UseFetchAndSetOrderSheetTabsParameter {
  data?: {
    orderSheetSetId: number;
    orderSheetId: number;
    lineSheetSetId: number;
    lineSheetId: number;
    tabIndexes: number[];
  };
  query?: SheetTabFilterQuery;
}

export default function useFetchAndSetOrderSheetTabs({
  data,
  query,
}: UseFetchAndSetOrderSheetTabsParameter) {
  const identity = useIdentity();
  const company = useMemo(() => {
    return identity?.company;
  }, [identity]);
  const { error: showError } = useAppToasts();

  const listAllSheetTabsKey = useMemo(():
    | {
        parameter: ListAllSheetTabsPathParameter;
        request: ListAllSheetTabsRequest;
      }
    | undefined => {
    if (data && company) {
      return {
        parameter: {
          by: company.type,
          companyId: company.id,
          orderSheetSetId: data.orderSheetSetId,
          orderSheetId: data.orderSheetId,
        },
        request: {
          lineSheetSetId: data.lineSheetSetId,
          lineSheetId: data.lineSheetId,
          sheetTabIndexes: data.tabIndexes,
        },
      };
    }
  }, [company, data]);

  const {
    isLoading: isListAllSheetTabsLoading,
    isValidating: isListAllSheetTabsValidating,
    data: listAllSheetTabsData,
    error: listAllSheetTabsError,
    mutate: mutateListAllSheetTabs,
  } = useListAllSheetTabs(
    listAllSheetTabsKey?.parameter,
    listAllSheetTabsKey?.request,
    {
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    showError(listAllSheetTabsError);
  }, [showError, listAllSheetTabsError]);

  const sheetKey = useMemo(() => {
    return {
      orderSheetSetId: data?.orderSheetSetId || -1,
      orderSheetId: data?.orderSheetId || -1,
    };
  }, [data?.orderSheetId, data?.orderSheetSetId]);

  const setInflatedTabsFamily = useSetRecoilState(inflatedTabsFamily(sheetKey));
  const { filterInflatedProducts, resetInflatedSheet } = useInflatedTabs();
  //query가 변경되면 반복 실행되어버림..
  //tabIndex, brandName__eq같은 filter가 변경되어도..
  useEffect(() => {
    if (
      !isListAllSheetTabsLoading &&
      !isListAllSheetTabsValidating &&
      listAllSheetTabsData &&
      sheetKey
    ) {
      setInflatedTabsFamily((prev) => {
        const tabs =
          prev?.tabs ||
          listAllSheetTabsData.sheetTabs.map((tab) => {
            return {
              ...tab,
              products: tab.products.map((product) => {
                return {
                  ...product,
                  priceAdjustedBy: {
                    ...product.priceAdjustedBy,
                    value: AppUtils.trimFloat(product.priceAdjustedBy.value),
                  },
                  // isFiltered: isProductFiltered(product, query),
                };
              }),
              isDirty: false,
            };
          });

        return {
          key: sheetKey,
          tabs: tabs.map((tab) => {
            return {
              ...tab,
              products: tab.products.map((product) => {
                return {
                  ...product,
                  isFiltered: isProductFiltered(product, query),
                };
              }),
            };
          }),
          // isDirty: false,
        };
      });
    }
  }, [
    isListAllSheetTabsValidating,
    isListAllSheetTabsLoading,
    listAllSheetTabsData,
    setInflatedTabsFamily,
    query,
    sheetKey,
  ]);


  useEffect(() => {
    return () => {
      resetInflatedSheet(sheetKey);
    };
  }, [
    listAllSheetTabsData,
    sheetKey,
  ]);


  const setInflatedTabsClean = useCallback(() => {
    if (data) {
      setInflatedTabsFamily((prev) => {
        if (prev) {
          return {
            ...prev,
            tabs: prev.tabs.map((tab) => {
              return {
                ...tab,
                isDirty: false,
              };
            }),
          };
        } else {
          return prev;
        }
      });
    }
  }, [setInflatedTabsFamily, data]);

  return {
    mutateListAllSheetTabs,
    setInflatedTabsClean,
  };
}
