import {
  OrderSheet,
  OrderSheetSet,
  OrderSheetStatus,
} from "@/features/order-sheets/order-sheet.type";
import AppButton from "@/features/line-sheet-sets/app-button";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useAppDialog from "@/features/ui/hooks/use-app-dialog";
import { uniq } from "lodash";
import AppSelect from "@/features/line-sheet-sets/app-select";
import useBulkUpdateOrderSheetStatus, {
  BulkUpdateOrderSheetStatusPathParameter,
  BulkUpdateOrderSheetStatusRequest,
  BulkUpdateOrderSheetStatusResponse
} from "@/features/order-status/hooks/use-bulk-update-order-sheet-status";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useIdentity from "@/features/ui/hooks/use-identity";
import useBulkUpdateOrderSheetSetStatus, {
  BulkUpdateOrderSheetSetStatusResponse,
} from "@/features/order-status/hooks/use-bulk-update-order-sheet-set-status";
import ConfirmByBoutiqueOrderSheetSetModal, {
  ConfirmByBoutiqueOrderSheetSetModalProps,
} from "@/features/order-status/modals/confirm-by-boutique-order-sheet-set-modal";
import useDisclosureWithData from "@/features/order-sheet-sets/hooks/use-disclosure-with-data";
import { ConfirmByBoutiqueOrderSheetSetResponse } from "@/features/order-status/hooks/use-confirm-by-boutique-order-sheet-set";
import ToBeUpdatedOrderSheetList, {
  ToBeUpdatedOrderSheet,
} from "@/features/order-status/modals/to-be-updated-order-sheet-list";
import { LineSheetStatus } from "@/features/line-sheet-sets/line-sheet-set.types";
import BulkConfirmByAgencyOrderSheetsModal, {
  BulkConfirmByAgencyOrderSheetsModalProps,
} from "@/features/order-status/modals/bulk-confirm-by-agency-order-sheets-modal";
import { useTranslation } from "react-i18next";
import { toLower, toTitle } from "@/utils/case";
import ChangeOrderSheetStatusConfirmMessage from "@/features/order-status/change-order-sheet-status-confirm-message";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

export interface UpdateOrderSheetStatusItem {
  orderSheetSetId: number;
  id: number;
  name: string;
  suffix?: string;
  status: OrderSheet["status"];
  createdByCompany: Pick<OrderSheetSet["createdByCompany"], "name">;
  lineSheet: { id: number; status: LineSheetStatus };
  issuedBy: { name: string };
  toBeSubmitted: { id: number; name: string } | null;
}

export interface UpdateOrderSheetSetStatusItem {
  id: number;
  status: OrderSheetSet["status"];
  createdByCompany: Pick<OrderSheetSet["createdByCompany"], "name">;
  orderSheets: {
    id: number;
    name: string;
    confirmedAmount: number;
    confirmedQuantity: number;
    issuedBy: { name: string };
    lineSheet: { id: number; status: LineSheetStatus };
    toBeSubmitted: { id: number; name: string } | null;
  }[];
}

export interface OrderSheetStatusUpdaterProps {
  items: UpdateOrderSheetStatusItem[] | UpdateOrderSheetSetStatusItem[];
  onUpdate: (
    response:
      | BulkUpdateOrderSheetSetStatusResponse
      | BulkUpdateOrderSheetStatusResponse
  ) => void;
}

export function isBulkUpdateOrderSheetSetStatusResponse(
  response:
    | BulkUpdateOrderSheetSetStatusResponse
    | BulkUpdateOrderSheetStatusResponse
): response is BulkUpdateOrderSheetSetStatusResponse {
  return "orderSheetSets" in response;
}

export function isBulkUpdateOrderSheetStatusResponse(
  response:
    | BulkUpdateOrderSheetSetStatusResponse
    | BulkUpdateOrderSheetStatusResponse
): response is BulkUpdateOrderSheetStatusResponse {
  return "orderSheets" in response;
}

function isOrderSheetSetItems(
  items: UpdateOrderSheetStatusItem[] | UpdateOrderSheetSetStatusItem[]
): items is UpdateOrderSheetSetStatusItem[] {
  return items.length === 0 || "orderSheets" in items[0];
}

function isOrderSheetItems(
  items: UpdateOrderSheetStatusItem[] | UpdateOrderSheetSetStatusItem[]
): items is UpdateOrderSheetStatusItem[] {
  return items.length === 0 || "orderSheetSetId" in items[0];
}

export default function OrderSheetStatusUpdater({
  items,
  onUpdate,
}: OrderSheetStatusUpdaterProps) {
  const identity = useIdentity();
  const { openDialog, closeDialog } = useAppDialog();
  const { error: showError } = useAppToasts();
  const [value, setValue] = useState<OrderSheetStatus>();
  const { t, tUpper } = useI18nHelper();
  const itemsKey = useMemo(() => {
    let prefix = isOrderSheetSetItems(items)
      ? "OrderSheetSetItems_"
      : "OrderSheetItems_";
    return (
      prefix +
      items
        .map((item) => item.id)
        .sort((a, b) => a - b)
        .join(",")
    );
  }, [items]);

  const options = useMemo((): AppSelectOption<OrderSheetStatus>[] => {
    let toStatuses: OrderSheetStatus[] = [];
    const fromStatuses = uniq(items.map((item) => item.status));

    if (fromStatuses.length === 1) {
      if (isOrderSheetItems(items)) {
        const orderSheetSetIds = uniq(
          items.map((item) => item.orderSheetSetId)
        );
        if (orderSheetSetIds.length === 1) {
          const fromStatus = fromStatuses[0];
          if (fromStatus === "ORDERED_TO_AGENCY") {
            toStatuses = [
              "AGENCY_CONFIRMED",
              "CANCELED",
              "MODIFICATION_REQUIRED",
            ];
          } else if (fromStatus === "AGENCY_CONFIRMED") {
            toStatuses = ["ORDERED_TO_BOUTIQUE"];
          } else if (fromStatus === "ORDERED_TO_BOUTIQUE") {
            toStatuses = [
              "CANCELED",
              "MODIFICATION_REQUIRED",
              "RESUBMIT_REQUIRED",
            ];
          } else if (fromStatus === "RESUBMIT_REQUIRED") {
            toStatuses = [
              "ORDERED_TO_BOUTIQUE",
              "RESUBMIT_COMPLETED",
              "CANCELED",
            ];
          } else if (fromStatus === "RESUBMIT_COMPLETED") {
            toStatuses = ["ORDERED_TO_BOUTIQUE", "CANCELED"];
          }
        }
      } else {
        const fromStatus = fromStatuses[0];
        if (fromStatus === "ORDERED_TO_AGENCY") {
          toStatuses = [
            "WORKING_DRAFT",
            "AGENCY_CONFIRMED",
            "CANCELED",
            "MODIFICATION_REQUIRED",
          ];
        } else if (fromStatus === "AGENCY_CONFIRMED") {
          toStatuses = [
            "WORKING_DRAFT",
            "ORDERED_TO_BOUTIQUE"
          ];
        } else if (fromStatus === "ORDERED_TO_BOUTIQUE") {
          toStatuses = [
            "RESUBMIT_REQUIRED",
            "BOUTIQUE_CONFIRMED",
            "CANCELED",
            "MODIFICATION_REQUIRED",
          ];
        } else if (fromStatus === "RESUBMIT_REQUIRED") {
          toStatuses = [
            "ORDERED_TO_BOUTIQUE",
            "RESUBMIT_COMPLETED",
            "CANCELED",
          ];
        } else if (fromStatus === "RESUBMIT_COMPLETED") {
          toStatuses = ["ORDERED_TO_BOUTIQUE", "CANCELED"];
        }
      }
    }

    return toStatuses.map((toStatus) => {
      return {
        name: tUpper(`order_sheet.status.${toLower(toStatus)}`),
        value: toStatus,
      };
    });
  }, [items, tUpper]);

  useEffect(() => {
    setValue(undefined);
  }, [itemsKey]);

  const toBeUpdateOrderSheets = useMemo((): ToBeUpdatedOrderSheet[] => {
    if (isOrderSheetSetItems(items)) {
      return items.flatMap((orderSheetSet) => {
        return orderSheetSet.orderSheets.map((orderSheet) => {
          return {
            id: orderSheet.id,
            name: orderSheet.name,
            orderedBy: orderSheetSet.createdByCompany.name,
            issuedBy: orderSheet.issuedBy.name,
            toBeSubmitted: orderSheet.toBeSubmitted,
          };
        });
      });
    } else {
      return items.map((orderSheet) => {
        return {
          id: orderSheet.id,
          name: orderSheet.name,
          orderedBy: orderSheet.createdByCompany.name,
          issuedBy: orderSheet.issuedBy.name,
          toBeSubmitted: orderSheet.toBeSubmitted,
        };
      });
    }
  }, [items]);

  const dialogMessage = useMemo(() => {
    return (
      <ToBeUpdatedOrderSheetList
        header={<ChangeOrderSheetStatusConfirmMessage status={value} />}
        orderSheets={toBeUpdateOrderSheets}
      />
    );
  }, [toBeUpdateOrderSheets, value]);

  const {
    isLoading: isBulkUpdateOrderSheetStatusLoading,
    data: bulkUpdateOrderSheetStatusData,
    error: bulkUpdateOrderSheetStatusError,
    fire: fireBulkUpdateOrderSheetStatus2,
  } = useBulkUpdateOrderSheetStatus();

  const fireBulkUpdateOrderSheetStatus = useCallback(
    async (parameter: BulkUpdateOrderSheetStatusPathParameter, request: BulkUpdateOrderSheetStatusRequest) => {
      await new Promise((resolve) => setTimeout(resolve, 5000));
      return fireBulkUpdateOrderSheetStatus2(parameter, request);
    },
    [fireBulkUpdateOrderSheetStatus2]
  );

  useEffect(() => {
    showError(bulkUpdateOrderSheetStatusError);
  }, [bulkUpdateOrderSheetStatusError, showError]);

  const {
    isLoading: isBulkUpdateOrderSheetSetStatusLoading,
    data: bulkUpdateOrderSheetSetStatusData,
    error: bulkUpdateOrderSheetSetStatusError,
    fire: fireBulkUpdateOrderSheetSetStatus2,
  } = useBulkUpdateOrderSheetSetStatus();

  const fireBulkUpdateOrderSheetSetStatus = useCallback(
    async (parameter: any, request: any) => {
      await new Promise((resolve) => setTimeout(resolve, 5000));
      return fireBulkUpdateOrderSheetSetStatus2(parameter, request);
    },
    [fireBulkUpdateOrderSheetSetStatus2]
  );

  useEffect(() => {
    if (bulkUpdateOrderSheetSetStatusError) {
      showError(bulkUpdateOrderSheetSetStatusError, {
        i18n: {
          keyPrefix: "bulk_update_order_sheet_set_status.error",
          ns: "order_sheet_sets",
        },
      });
    }
  }, [bulkUpdateOrderSheetSetStatusError, showError]);

  const [
    confirmByBoutiqueOrderSheetSetData,
    setConfirmByBoutiqueOrderSheetSetData,
  ] = useState<ConfirmByBoutiqueOrderSheetSetResponse>();

  const {
    isOpen: isBulkConfirmByAgencyOrderSheetsModalOpen,
    onClose: onBulkConfirmByAgencyOrderSheetsModalClose,
    data: bulkConfirmByAgencyOrderSheetsModalData,
    openWithData: openBulkConfirmByAgencyOrderSheetsModalWithData,
  } = useDisclosureWithData<BulkConfirmByAgencyOrderSheetsModalProps>();

  const {
    isOpen: isConfirmByBoutiqueOrderSheetSetModalOpen,
    onClose: onConfirmByBoutiqueOrderSheetSetModalClose,
    onOpen: onConfirmByBoutiqueOrderSheetSetModalOpen,
    data: confirmByBoutiqueOrderSheetSetModalData,
    openWithData: openConfirmByBoutiqueOrderSheetSetModalWithData,
  } = useDisclosureWithData<ConfirmByBoutiqueOrderSheetSetModalProps>();

  useEffect(() => {
    if (
      bulkUpdateOrderSheetStatusData ||
      bulkUpdateOrderSheetSetStatusData ||
      confirmByBoutiqueOrderSheetSetData
    ) {
      // if (dialogRef.current) {
      //   closeDialog(dialogRef.current);
      // }
      setValue(undefined);
      if (bulkUpdateOrderSheetSetStatusData) {
        onUpdate(bulkUpdateOrderSheetSetStatusData);
      } else if (bulkUpdateOrderSheetStatusData) {
        onUpdate(bulkUpdateOrderSheetStatusData);
      } else if (confirmByBoutiqueOrderSheetSetData) {
        onUpdate({
          orderSheetSets: [confirmByBoutiqueOrderSheetSetData],
        });
      }
    }
  }, [
    bulkUpdateOrderSheetStatusData,
    bulkUpdateOrderSheetSetStatusData,
    confirmByBoutiqueOrderSheetSetData,
    closeDialog,
    onUpdate,
  ]);

  return (
    <>
      <AppSelect<OrderSheetStatus>
        width={"144px"}
        name={toTitle(t("status"))}
        options={options}
        isDisabled={options.length === 0}
        value={value}
        onSelect={(v) => setValue(v)}
      />
      <AppButton
        isDisabled={value === undefined}
        isLoading={
          isBulkUpdateOrderSheetStatusLoading ||
          isBulkUpdateOrderSheetSetStatusLoading
        }
        onClick={async () => {
          if (value === "BOUTIQUE_CONFIRMED") {
            if (
              value &&
              identity &&
              identity.company?.type === "AGENCY" &&
              isOrderSheetSetItems(items)
            ) {
              if (items.length === 1) {
                openConfirmByBoutiqueOrderSheetSetModalWithData((prev) => {
                  return {
                    agencyId: identity.company?.id!,
                    orderSheetSetId: items[0].id,
                    orderSheetSet: items[0],
                    toBeUpdateOrderSheets,
                    onConfirm: setConfirmByBoutiqueOrderSheetSetData,
                  };
                });
              } else {
                showError({
                  error: {
                    code: -1,
                    status: "NOT SUPPORTED OPERATION",
                    message: "Confirm by boutique is not supported for bulk.",
                  },
                });
              }
            }
          } else if (value === "AGENCY_CONFIRMED") {
            if (identity?.company?.type === "AGENCY") {
              if (isOrderSheetItems(items)) {
                openBulkConfirmByAgencyOrderSheetsModalWithData((prev) => {
                  return {
                    agencyId: identity.company!.id,
                    orderSheetSetId: items[0].orderSheetSetId,
                    orderSheets: items,
                    issuedByCompany: items[0].createdByCompany,
                    createdByCompany: items[0].createdByCompany,
                    onConfirm: onUpdate,
                  };
                });
              } else if (isOrderSheetSetItems(items)) {
                //헬이구만ㅇㅋ
                openBulkConfirmByAgencyOrderSheetsModalWithData((prev) => {
                  return {
                    agencyId: identity.company!.id,
                    orderSheetSetId: items[0].id,
                    orderSheets: items.flatMap((item) => {
                      return item.orderSheets;
                    }),
                    issuedByCompany: items[0].createdByCompany,
                    createdByCompany: items[0].createdByCompany,
                    onConfirm: onUpdate,
                  };
                });
              }
            }
          } else {
            const isConfirmed = await openDialog({
              title: toTitle(t("change_status")),
              message: dialogMessage,
              confirmName: toTitle(t("change")),
              onConfirm: async () => {
                if (value && identity && identity.company?.type === "AGENCY") {
                  if (isOrderSheetItems(items)) {
                    return fireBulkUpdateOrderSheetStatus(
                      {
                        by: identity.company.type,
                        companyId: identity.company.id,
                        orderSheetSetId: items[0].orderSheetSetId,
                      },
                      {
                        status: value,
                        orderSheetIds: items.map((item) => item.id),
                      }
                    );
                  } else if (isOrderSheetSetItems(items)) {
                    return fireBulkUpdateOrderSheetSetStatus(
                      {
                        agencyId: identity.company.id,
                      },
                      {
                        status: value,
                        orderSheetSetIds: items.map((item) => item.id),
                      }
                    );
                  }
                }
              },
            });
          }
        }}
      >
        {toTitle(t("change_status"))}
      </AppButton>
      {confirmByBoutiqueOrderSheetSetModalData && (
        <ConfirmByBoutiqueOrderSheetSetModal
          isOpen={isConfirmByBoutiqueOrderSheetSetModalOpen}
          onClose={onConfirmByBoutiqueOrderSheetSetModalClose}
          {...confirmByBoutiqueOrderSheetSetModalData}
        />
      )}
      {isBulkConfirmByAgencyOrderSheetsModalOpen &&
        bulkConfirmByAgencyOrderSheetsModalData && (
          <BulkConfirmByAgencyOrderSheetsModal
            isOpen={isBulkConfirmByAgencyOrderSheetsModalOpen}
            onClose={onBulkConfirmByAgencyOrderSheetsModalClose}
            {...bulkConfirmByAgencyOrderSheetsModalData}
          />
        )}
    </>
  );
}
