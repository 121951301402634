import useIdentity from "@/features/ui/hooks/use-identity";
import { DEFAULT_LIST_LINE_SHEET_PAIRS_PAGE_SIZE } from "@/features/line-sheet-sets/hooks/use-list-line-sheet-set-pairs";
import { Box, Flex } from "@chakra-ui/react";
import AppLineSheetTypeSelector from "@/features/line-sheet-sets/app-line-sheet-type-selector";
import AppCompanySelect from "@/features/line-sheet-sets/app-company-select";
import AppButton from "@/features/line-sheet-sets/app-button";
import AppPagination from "@/features/line-sheet-sets/app-pagination";
import { useEffect, useMemo } from "react";
import BuyerLineSheetSetGrid from "@/features/order-sheet-sets/buyer-line-sheet-set-grid";
import { useRecoilValue } from "recoil";
import { buyerLineSheetSetRowsMutateId } from "@/features/order-sheet-sets/recoils/buyer-line-sheet-set-rows-state";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useBuyerLineSheetSetRows from "@/features/order-sheet-sets/hooks/use-buyer-line-sheet-set-rows";
import AppListHeader from "@/features/ui/app-list-header";
import useListDenseLineSheetSets, {
  ListDenseLineSheetSetsPathParameter,
  ListDenseLineSheetSetsRequest,
} from "@/features/line-sheet-sets/hooks/use-list-dense-line-sheet-sets";
import AppQueryController from "@/features/ui/app-query-controller";
import { toTitle } from "@/utils/case";
import AppSeasonMultiSelect from "@/features/line-sheet-sets/app-season-multi-select";
import { OrderByItem } from "@/features/types";
import useTypedSearchParams from "@/features/invoices/hooks/use-typed-search-params";
import { PersistedLineSheetSetListQuery } from "@/features/order-sheet-sets/agency-line-sheet-set-list-page";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

export default function BuyerLineSheetSetListPage() {
  const identity = useIdentity();
  const { setRows } = useBuyerLineSheetSetRows();
  const rowsMutateId = useRecoilValue(buyerLineSheetSetRowsMutateId);
  const { error: showError } = useAppToasts();
  const [query, setQuery] =
    useTypedSearchParams<PersistedLineSheetSetListQuery>();

  const { t } = useI18nHelper();

  const listDenseLineSheetSetsKey = useMemo(():
    | {
        parameter: ListDenseLineSheetSetsPathParameter;
        request: ListDenseLineSheetSetsRequest;
      }
    | undefined => {
    if (identity?.company) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request: {
          ...query,
          pageSize: DEFAULT_LIST_LINE_SHEET_PAIRS_PAGE_SIZE,
          pageNumber: query.pageNumber || 1,
          orderBy: [new OrderByItem("publishedAt", "descend")],
          isPublished__eq: true,
        },
      };
    }
  }, [identity, query]);

  const {
    isLoading: isDenseLineSheetSetsLoading,
    data: listDenseLineSheetSetsData,
    mutate: mutateListDenseLineSheetSets,
    error: listDenseLineSheetSetsError,
  } = useListDenseLineSheetSets(
    listDenseLineSheetSetsKey?.parameter,
    listDenseLineSheetSetsKey?.request,
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  useEffect(() => {
    if (listDenseLineSheetSetsError) {
      showError(listDenseLineSheetSetsError);
    }
  }, [listDenseLineSheetSetsError, showError]);

  useEffect(() => {
    setRows(listDenseLineSheetSetsData?.lineSheetSets);
  }, [listDenseLineSheetSetsData, setRows]);

  useEffect(() => {
    return () => {
      mutateListDenseLineSheetSets((prev) => prev, false);
    };
  }, [mutateListDenseLineSheetSets]);

  useEffect(() => {
    if (rowsMutateId > 0) {
      mutateListDenseLineSheetSets();
    }
  }, [mutateListDenseLineSheetSets, rowsMutateId]);

  return (
    <Flex
      flexDirection={"column"}
      gap={"12px"}
      height={"100%"}
      paddingBottom={"16px"}
    >
      <AppListHeader
        filters={[
          <AppLineSheetTypeSelector
            key={"AgencyLineSheetSetListFilter_Type"}
            value={query.type__eq}
            onSelect={(value) => {
              setQuery((prev) => {
                return {
                  ...prev,
                  type__eq: value,
                  pageNumber: 1,
                };
              });
            }}
          />,
          <AppSeasonMultiSelect
            key={"season"}
            value={query.season__in}
            onSelect={(value) => {
              setQuery((prev) => {
                return {
                  ...prev,
                  season__in: value,
                  pageNumber: 1,
                };
              });
            }}
          />,
          <AppCompanySelect
            key={"AgencyLineSheetSetListFilter_Brand"}
            name={toTitle(t("common:brand"))}
            types={["BRAND"]}
            id={query["madeByCompany.id__eq"]}
            onSelect={(company) => {
              setQuery((prev) => {
                return {
                  ...prev,
                  "madeByCompany.id__eq": company?.id,
                  pageNumber: 1,
                };
              });
            }}
          />,
          <AppCompanySelect
            key={"AgencyLineSheetSetListFilter_IssuedBy"}
            name={toTitle(t("issued_by"))}
            types={["BOUTIQUE"]}
            id={query["issuedByCompany.id__eq"]}
            onSelect={(company) => {
              setQuery((prev) => {
                return {
                  ...prev,
                  "issuedByCompany.id__eq": company?.id,
                  pageNumber: 1,
                };
              });
            }}
          />,
          <AppButton
            key={"AgencyLineSheetSetListFilter_ResetFilter"}
            variant={"normal"}
            size={"medium"}
            onClick={() => {
              setQuery({
                pageNumber: 1,
              });
            }}
          >
            {toTitle(t("reset_filter"))}
          </AppButton>,
          <AppQueryController
            key={"AgencyLineSheetSetListFilter_Query"}
            value={query.query}
            onChange={(value) =>
              setQuery((prev) => {
                return {
                  ...prev,
                  query: value,
                  pageNumber: 1,
                };
              })
            }
          />,
        ]}
        actions={[]}
      />
      <Box
        flexGrow={1}
        overflow={"auto"}
        style={{
          scrollbarGutter: "stable",
        }}
      >
        <BuyerLineSheetSetGrid />
      </Box>
      <Flex flexDirection={"row"} justifyContent={"center"}>
        <AppPagination
          capacity={10}
          pageSize={DEFAULT_LIST_LINE_SHEET_PAIRS_PAGE_SIZE}
          totalCount={listDenseLineSheetSetsData?.totalCount}
          pageNumber={query.pageNumber}
          setPageNumber={(pageNumber) =>
            setQuery((prev) => {
              return {
                ...prev,
                pageNumber,
              };
            })
          }
        />
      </Flex>
    </Flex>
  );
}
