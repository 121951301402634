import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { LineSheetStatus } from "@/features/line-sheet-sets/line-sheet-set.types";
import AppButton from "@/features/line-sheet-sets/app-button";
import useListOrderSheetStatistics, {
  ListOrderSheetStatisticsPathParameter,
  ListOrderSheetStatisticsRequest,
} from "@/features/line-sheet-sets/hooks/use-list-order-sheet-statistics";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import * as Yup from "yup";
import { Field, FieldProps, Form, Formik, FormikProps } from "formik";
import { OrderSheetSet } from "@/features/order-sheets/order-sheet.type";
import AppPriceInput from "@/features/invoices/app-price-input";
import { withWithHandleChange } from "@/utils/form";
import { produce } from "immer";
import useBulkConfirmByAgencyOrderSheetSets, {
  BulkConfirmByAgencyOrderSheetsResponse,
} from "@/features/order-status/hooks/use-bulk-confirm-by-agency-order-sheets";
import useIdentity from "@/features/ui/hooks/use-identity";
import { useTranslation } from "react-i18next";
import useYupHelper from "@/features/ui/form/use-yup-helper";
import { toTitle } from "@/utils/case";
import ChangeOrderSheetStatusConfirmMessage from "@/features/order-status/change-order-sheet-status-confirm-message";

export interface BulkConfirmByAgencyOrderSheetsModalProps {
  agencyId: number;
  orderSheetSetId: number;
  orderSheets: {
    id: number;
    name: string;
    lineSheet: {
      id: number;
      status: LineSheetStatus;
    };
  }[];
  createdByCompany: Pick<OrderSheetSet["createdByCompany"], "name">;
  issuedByCompany: {
    name: string;
  };
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (response: BulkConfirmByAgencyOrderSheetsResponse) => void;
}

interface OrderedFigure {
  orderSheetId: number;
  name: string;
  createdBy: string;
  issuedBy: string;
  quantity?: number;
  amountWithoutCondition?: number;
  amount?: number;
  isReadOnly: boolean;
}

interface FormFields {
  orderedFigures: OrderedFigure[];
}

export default function BulkConfirmByAgencyOrderSheetsModal({
  agencyId,
  orderSheetSetId,
  issuedByCompany,
  createdByCompany,
  orderSheets,
  isOpen,
  onClose,
  onConfirm,
}: BulkConfirmByAgencyOrderSheetsModalProps) {
  const { error: showError } = useAppToasts();
  const identity = useIdentity();

  const { t } = useTranslation();
  const { withMessage } = useYupHelper({
    ns: "order_sheet_sets",
    prefix: "confirm_by_agency_form",
  });

  const validateSchema = useMemo(() => {
    return Yup.object<FormFields>({
      orderedFigures: Yup.array()
        .label("ordered_figures")
        .of(
          Yup.object({
            quantity: Yup.number()
              .label("ordered_figures.quantity")
              .required(withMessage.required)
              .positive(withMessage.positive),
            amount: Yup.number()
              .label("ordered_figures.amount")
              .required(withMessage.required),
            amountWithoutCondition: Yup.number()
              .label("ordered_figures.amount_without_condition")
              .required(withMessage.required),
          })
        )
        .min(1, withMessage.min)
        .required(),
    });
  }, [withMessage]);

  const key = useMemo(():
    | {
        parameter: ListOrderSheetStatisticsPathParameter;
        request: ListOrderSheetStatisticsRequest;
      }
    | undefined => {
    const parseOrderSheetIds = orderSheets
      .filter((orderSheet) => orderSheet.lineSheet.status === "PARSED")
      .map((orderSheet) => orderSheet.id);

    if (parseOrderSheetIds.length > 0) {
      return {
        parameter: {
          by: "AGENCY",
          companyId: agencyId,
          orderSheetSetId,
        },
        request: {
          orderSheetIds: parseOrderSheetIds,
        },
      };
    }
  }, [agencyId, orderSheetSetId, orderSheets]);

  const {
    error: listOrderSheetStatisticsError,
    isLoading: listOrderSheetStatisticsIsLoading,
    data: listOrderSheetStatisticsData,
  } = useListOrderSheetStatistics(key?.parameter, key?.request);

  useEffect(() => {
    if (listOrderSheetStatisticsError) {
      showError(listOrderSheetStatisticsError.message);
    }
  }, [listOrderSheetStatisticsError, showError]);

  const [isFormikRefSet, setIsFormikRefSet] = useState<boolean>(false);

  useEffect(() => {
    if (listOrderSheetStatisticsData && isFormikRefSet) {
      if (formikRef.current !== null) {
        const next = produce(
          formikRef.current.values.orderedFigures,
          (draft) => {
            listOrderSheetStatisticsData.orderSheetStatistics.forEach(
              (statistic) => {
                const index = draft.findIndex(
                  (i) => i.orderSheetId === statistic.orderSheetId
                );
                if (index > -1) {
                  draft[index].quantity = statistic.totalOrderedQuantity;
                  draft[index].amount = statistic.totalOrderedAmount;
                  draft[index].amountWithoutCondition =
                    statistic.totalOrderedAmountWithoutCondition;
                }
              }
            );
          }
        );
        formikRef.current?.setFieldValue("orderedFigures", next);
      }
    }
  }, [listOrderSheetStatisticsData, isFormikRefSet]);

  const formikRef = useRef<FormikProps<FormFields> | null>(null);

  const validate = async (values: FormFields) => {
    try {
      await validateSchema.validate(values, { abortEarly: false });
    } catch (error) {
      showError(error);
      return error;
    }
  };

  const {
    isLoading: isConfirmByAgencyOrderSheetsLoading,
    data: confirmByAgencyOrderSheetsData,
    error: confirmByAgencyOrderSheetsError,
    fire: fireConfirmByAgencyOrderSheetSets,
  } = useBulkConfirmByAgencyOrderSheetSets();

  useEffect(() => {
    if (confirmByAgencyOrderSheetsError) {
      showError(confirmByAgencyOrderSheetsError);
    }
  }, [showError, confirmByAgencyOrderSheetsError]);

  const handleSubmit = useCallback(
    (values: FormFields) => {
      if (identity?.company?.type === "AGENCY") {
        fireConfirmByAgencyOrderSheetSets(
          {
            agencyId: identity.company.id,
            orderSheetSetId: orderSheetSetId,
          },
          {
            orderedFigures: values.orderedFigures.map((orderedFigure) => {
              return {
                orderSheetId: orderedFigure.orderSheetId,
                quantity: orderedFigure.quantity || 0,
                amountWithoutCondition:
                  orderedFigure.amountWithoutCondition || 0,
                amount: orderedFigure.amount || 0,
              };
            }),
          }
        );
      }
    },
    [identity, fireConfirmByAgencyOrderSheetSets, orderSheetSetId]
  );

  useEffect(() => {
    if (confirmByAgencyOrderSheetsData) {
      onClose();
      onConfirm(confirmByAgencyOrderSheetsData);
    }
  }, [confirmByAgencyOrderSheetsData, onConfirm, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          color={"#444440"}
          fontSize={"14px"}
          background={"#F7F9FA"}
          borderBottom={"0.5px solid #8F8F8C"}
        >
          {toTitle(t("change_status"))}
        </ModalHeader>
        <ModalBody>
          <Formik<FormFields>
            innerRef={(ref) => {
              setIsFormikRefSet(true);
              formikRef.current = ref;
            }}
            initialValues={{
              orderedFigures: orderSheets.map((orderSheet) => {
                return {
                  orderSheetId: orderSheet.id,
                  name: orderSheet.name,
                  createdBy: createdByCompany.name,
                  issuedBy: issuedByCompany.name,
                  isReadOnly: orderSheet.lineSheet.status === "PARSED",
                };
              }),
            }}
            validate={validate}
            validateOnChange={false}
            validateOnBlur={false}
            validateOnMount={false}
            onSubmit={(values, formikHelpers) => {
              handleSubmit(values);
              formikHelpers.setSubmitting(false);
            }}
          >
            {(props) => {
              return (
                <Form>
                  <Flex
                    flexDirection={"column"}
                    fontSize={"12px"}
                    gap={"8px"}
                    color={"#444440"}
                  >
                    <Heading
                      as={"h3"}
                      fontSize={"inherit"}
                      fontWeight={"400"}
                      mt={"8px"}
                    >
                      <ChangeOrderSheetStatusConfirmMessage
                        status={"AGENCY_CONFIRMED"}
                      />
                    </Heading>
                    <Grid
                      gridTemplateColumns={"1fr 0.4fr 0.4fr repeat(3, 160px)"}
                      gap={"8px"}
                      alignItems={"end"}
                      justifyItems={"stretch"}
                      borderBottom={"0.5px solid var(--gray, #D4D4D1)"}
                      marginTop={"8px"}
                    >
                      <GridItem>{toTitle(t("title"))}</GridItem>
                      <GridItem>{toTitle(t("ordered_by"))}</GridItem>
                      <GridItem>{toTitle(t("issued_by"))}</GridItem>
                      <GridItem>
                        {toTitle(t("ordered_amount_without_condition"))}
                      </GridItem>
                      <GridItem>{toTitle(t("ordered_amount"))}</GridItem>
                      <GridItem>{toTitle(t("ordered_quantity"))}</GridItem>
                    </Grid>

                    <Grid
                      gridTemplateColumns={"1fr 0.4fr 0.4fr repeat(3, 160px)"}
                      gridAutoRows={"24px"}
                      gap={"8px"}
                      alignItems={"end"}
                    >
                      <Field name={"orderedFigures"}>
                        {(
                          props: FieldProps<
                            FormFields["orderedFigures"],
                            FormFields
                          >
                        ) => {
                          return props.field.value.map(
                            (orderedFigure, index) => {
                              const onChange = (value: OrderedFigure) => {
                                props.form.setFieldValue(
                                  "orderedFigures",
                                  produce(props.field.value, (draft) => {
                                    draft[index] = value;
                                  })
                                );
                              };
                              const withHandleChange = withWithHandleChange(
                                orderedFigure,
                                onChange
                              );

                              return (
                                <React.Fragment key={"OrderedFigure_" + index}>
                                  <GridItem
                                    overflow={"hidden"}
                                    textOverflow={"ellipsis"}
                                    whiteSpace={"nowrap"}
                                    title={orderedFigure.name}
                                  >
                                    {orderedFigure.name}
                                  </GridItem>
                                  <GridItem
                                    overflow={"hidden"}
                                    textOverflow={"ellipsis"}
                                    whiteSpace={"nowrap"}
                                    title={orderedFigure.createdBy}
                                  >
                                    {orderedFigure.createdBy}
                                  </GridItem>
                                  <GridItem
                                    overflow={"hidden"}
                                    textOverflow={"ellipsis"}
                                    whiteSpace={"nowrap"}
                                    title={orderedFigure.issuedBy}
                                  >
                                    {orderedFigure.issuedBy}
                                  </GridItem>
                                  <GridItem>
                                    <AppPriceInput
                                      isDisabled={orderedFigure.isReadOnly}
                                      currency={"EUR"}
                                      value={{
                                        value:
                                          orderedFigure.amountWithoutCondition,
                                        currency: "EUR",
                                      }}
                                      onChange={(value) => {
                                        withHandleChange(
                                          "amountWithoutCondition"
                                        )(value?.value);
                                      }}
                                    />
                                  </GridItem>
                                  <GridItem>
                                    <AppPriceInput
                                      currency={"EUR"}
                                      isDisabled={orderedFigure.isReadOnly}
                                      value={{
                                        value: orderedFigure.amount,
                                        currency: "EUR",
                                      }}
                                      onChange={(value) => {
                                        withHandleChange("amount")(
                                          value?.value
                                        );
                                      }}
                                    />
                                  </GridItem>
                                  <GridItem>
                                    <AppPriceInput
                                      currency={"EA"}
                                      isDisabled={orderedFigure.isReadOnly}
                                      value={{
                                        value: orderedFigure.quantity,
                                        currency: "EA",
                                      }}
                                      onChange={(value) => {
                                        withHandleChange("quantity")(
                                          value?.value
                                        );
                                      }}
                                    />
                                  </GridItem>
                                </React.Fragment>
                              );
                            }
                          );
                        }}
                      </Field>
                    </Grid>
                  </Flex>
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
        <ModalFooter display={"flex"} gap={"12px"}>
          <AppButton
            onClick={() => {
              onClose();
            }}
          >
            {toTitle(t("not_now"))}
          </AppButton>
          <AppButton
            width={"160px"}
            variant={"primary"}
            isLoading={isConfirmByAgencyOrderSheetsLoading}
            onClick={() => {
              if (formikRef.current) {
                formikRef.current.submitForm();
              }
            }}
          >
            {toTitle(t("change"))}
          </AppButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
