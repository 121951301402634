import { usePersistedQuery } from "@/features/order-sheet-sets/hooks/use-persisted-query";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import { SheetTabFilterQuery } from "@/features/order-sheet-sets/sheet-tab-filter";

export interface OrderSheetSetSelectQuery {
  orderSheetId?: number;
  orderSheetTabIndex?: number;
  lineSheetId?: number;
  lineSheetTabIndex?: number;
}

export interface OrderSheetSetPagePersistedQuery
  extends OrderSheetSetSelectQuery,
    SheetTabFilterQuery {
  referer: string;
}

export default function useOrderSheetSetPagePersistedQuery() {
  const { value, set } = usePersistedQuery<OrderSheetSetPagePersistedQuery>({
    entries: {
      referer: {
        parse: StringTransformer.parseString,
        stringify: StringTransformer.stringifyString,
      },
      orderSheetId: {
        parse: StringTransformer.parseId,
        stringify: StringTransformer.stringifyId,
      },
      orderSheetTabIndex: {
        parse: StringTransformer.parseId,
        stringify: StringTransformer.stringifyId,
      },
      lineSheetId: {
        parse: StringTransformer.parseId,
        stringify: StringTransformer.stringifyId,
      },
      brandName__eq: {
        parse: StringTransformer.parseString,
        stringify: StringTransformer.stringifyString,
      },
      gender__eq: {
        parse: StringTransformer.parseString,
        stringify: StringTransformer.stringifyString,
      },
      category__eq: {
        parse: StringTransformer.parseString,
        stringify: StringTransformer.stringifyString,
      },
      quantity__gte: {
        parse: StringTransformer.parseId,
        stringify: StringTransformer.stringifyId,
      },
      brandName__like: {
        parse: StringTransformer.parseString,
        stringify: StringTransformer.stringifyString,
      },
      lineSheetTabIndex: {
        parse: StringTransformer.parseId,
        stringify: StringTransformer.stringifyId,
      },
    },
  });

  return {
    value,
    set,
  };
}
